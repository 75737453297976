.srv-validation-message {
  text-align: left;
  font-size: 11px;
  color: #b74545;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

.ta-c {
  text-align: center !important;
}

.d-flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.overflow-table {
  overflow: auto;
}

.pointer {
  cursor: pointer;
}

.gap-1 {
  gap: 1em;
}

.gap-05 {
  gap: 0.5em;
}

.gap-3 {
  gap: 1.5em;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0 !important;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0 !important;
}

.p-1 {
  padding: 1em;
}

.pl-1 {
  padding-left: 1em;
}

.pr-1 {
  padding-right: 1em;
}

.pt-1 {
  padding-top: 1em;
}

.pb-05 {
  padding-bottom: 0.5em;
}

.checkbox-img {
  width: 23px;
  height: 23px;
}

.btn-primary3 {
  color: #fff;
  background-color: #16805e;
}

.btn-primary3:hover {
  color: #fff;
  background-color: #16805e;
}

.btn-primary2 {
  color: #fff;
  background-color: #1fabb5;
  border-color: #1fabb5;
}

.btn-primary2:hover {
  color: #fff;
  background-color: #198c94;
  border-color: #188289;
}

/* Margin Units Starts Here */

.mt-3 {
  margin-top: 1em;
}

.mr-3 {
  margin-right: 1em;
}

.ml-3 {
  margin-left: 1em;
}

/* Margin Units Ends Here */

.section-title {
  color: #676a6c;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.anchor {
  color: #1fabb5 !important;
}

.card-title {
  color: rgb(33, 33, 33);
  font-size: 20px;
  font-weight: 800;
}

.card-title6 {
  font-family: inherit;
  line-height: 1.1;
  color: rgb(33, 33, 33);
  color: inherit;
  margin: 0 0 7px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.card-title6 h5 {
  text-transform: capitalize;
}

.card-text h1 {
  font-family: inherit;
  line-height: 1.1;
  color: inherit;
  /* color: #212121; */
  margin: 0 0 7px;
  padding: 0;
  font-weight: 500;
  font-size: 30px;
}

.card-text small {
  font-size: 100%;
  text-transform: capitalize;
}

.card-body {
  color: rgb(33, 33, 33);
  font-size: 36px;
  font-weight: 800;
}

.dashboard-card-container>div {
  flex: 1;
}

@media only screen and (max-width: 900px) and (min-width: 300px) {
  .sm-flex-col {
    display: flex;
    flex-direction: column;
  }

  .dashboard-card-container {
    flex-direction: column;
    padding: 1em;
  }
}

.modalClass {
  text-align: center;
  margin-top: 100px;
}

@media (min-width: 300px) and (max-width: 479px) {
  .header-top-spacing .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .in-play-row-right.in-play-right-box-color {
    height: 47px;
    width: 100%;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 7px;
  }

  .in-play-row-left.live-report-in-play-row-left {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .in-play-row-right>.match-time-dtl {
    color: #fff;
    text-align: center;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    margin: 15px;
  }

  p.match-time-dtl-date.live-report-match-time,
  p.match-time-dtl-mounth.live-report-match-month,
  p.match-time-dtl-mounth.live-report-match-date {
    margin: 0;
    padding-left: 6px;
    font-size: initial;
  }

  .header-top-spacing {
    margin: 0 20px;
  }

  .btn-rounded {
    font-size: 3vw !important;
    padding: .86vw 2.6vw !important;
  }

  .ibox-title.anvIbox {
    overflow: auto;
  }

  .ibox-title.anvIbox .d-flex.justify-end.gap-05 {
    justify-content: flex-start;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .header-top-spacing .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .in-play-row-right.in-play-right-box-color {
    height: 47px;
    width: 100%;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 7px;
  }

  .in-play-row-left.live-report-in-play-row-left {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .in-play-row-right>.match-time-dtl {
    color: #fff;
    text-align: center;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    margin: 15px;
  }

  p.match-time-dtl-date.live-report-match-time,
  p.match-time-dtl-mounth.live-report-match-month,
  p.match-time-dtl-mounth.live-report-match-date {
    margin: 0;
    padding-left: 6px;
    font-size: initial;
  }

  .header-top-spacing {
    margin: 0 20px;
  }

  .btn-rounded {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }

  .ibox-title.anvIbox {
    overflow: auto;
  }


}

.head_style {
  height: 57px;
  padding: 0px;
  line-height: 16px;
}

.paginate_class {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.flex_class_colletion {
  display: flex;
  flex-direction: row;
}

select.selectClass {
  display: inline-block;
  width: 45%;
  height: 34px;
}

p.text_danger {
  margin-top: 10px;
  color: red;
}

.modal-header {
  position: relative !important;
}

.modal-header .close {
  position: absolute;
  top: 15px;
  right: 10px;
}

.css_gaping {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-on-off {
  background-color: #2196f3;
}

input:checked+.slider-on-off {
  background-color: red !important;
}

input:checked+.slider {
  background-color: #2196f3;
  min-width: 50px;
  min-height: 30px;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.hello_class {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.body_fonts_style {
  font-family: sans-serif;
}

.font_h2_class {
  font-weight: normal;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font_dark_1 {
  font-weight: 700;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

span.text_color_span {
  display: flex;
  color: black !important;
  font-weight: lighter;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 2px;
  padding-left: 2px;
}

#event_table_403636 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.flex_div_class {
  display: flex;
  flex-direction: row;
}

/* //////// colour checkbox ////////// */

.color-checkbox {
  margin-top: 5px;
  width: 22px;
  height: 22px;
  margin-right: 18px;
  cursor: pointer;
}

.color-checkbox-mark {
  display: inline-block;
  width: 10px;
  height: 35px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.color-checkbox-mark-stem {
  border: 2px #ffffff solid;
  border-top: 0;
  border-left: 0;
  height: 14px;
  width: 8px;
}

/* ////////////////////////// lader result card start /////////////////////// */
.result-container {
  background-color: #fff;
  color: #49494a;
  padding: 10px;
  font-family: "Open Sans", sans-serif !important;
  text-align: center;
  vertical-align: middle !important;
  margin-bottom: 10px;
}

.round-id {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
}

.card-container-box {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.card-container img {
  height: 50px;
  width: 41px;
  margin: 5px 10px;
}

.card-container b {
  font-size: 20px;
  margin-right: 10px;
}

.row1 {
  justify-content: flex-start;
}

.result-text {
  margin-top: 10px;
  background-color: var(--light-bg);
  padding: 10px;
  box-shadow: 0 0 5px #aaa;
}

.tr-danger {
  background-color: #f2dede !important;
}

.tr-success {
  background-color: #e4f2de !important;
}

.tr-warning {
  background-color: #ffeece !important;
}

.lock_toggle_box {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.lock_toggle_box label {
  margin-right: 10px;
}

/* ////////////////////////// lader result card end /////////////////////// */
/* ////////////////////////// matka styling /////////////////////// */
.matka_actBtn {
  color: white;
  background: #57c657;
  border: none;
  padding: 6px 4px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 80px;
}

.matka_inActBtn {
  color: white;
  background: #e67878;
  border: none;
  padding: 6px 4px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  width: 80px;
}

.chkbx_container {
  display: flex;
  align-items: end;
}

.matka_color_picker {
  width: 20px;
  height: 20px;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
}

.matka_color_input {
  display: flex;
  align-items: center;
}

.matka_color_input label {
  margin: 0 5px !important;
}

/* ////////////////////////// matka styling /////////////////////// */
/* //////////////////////////  styling /////////////////////// */
.filter_box {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.filter_btn {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 10px;
  padding: 4px 13px;
  margin: 0 3px;
}

.global_setting_modal>.modal-dialog {
  top: 200px;
}

/* //////////////////////////  styling /////////////////////// */

@media only screen and (max-width: 399px) and (min-width: 300px) {
  .inner_text_fontSize {
    font-size: 10px;
  }

  #event_table_403636 {
    font-size: 18px !important;
  }


}

@media only screen and (max-width: 534px) and (min-width: 400px) {
  .inner_text_fontSize {
    font-size: 12px;
  }

  #event_table_403636 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 300px) {
  .row.session-resp-tbl.table-responsive {
    width: auto;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 535px) {
  .inner_text_fontSize {
    font-size: 15px;
  }

  #event_table_403636 {
    font-size: 21px !important;
  }
}

@media only screen and (max-width: 567px) and (min-width: 320px) {
  .responsive_input {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 568px) {
  .responsive_input {
    width: 85%;
  }
}

@media only screen and (max-width: 557px) and (min-width: 386px) {
  .hide_div {
    display: none;
  }

  .live_report_toggle .flex-center1 {
    display: block;
  }

  .live_report_toggle div {
    width: 100%;
  }
}

@media only screen and (max-width: 386px) and (min-width: 280px) {
  .hide_div {
    display: none;
  }

  .live_report_toggle div {
    display: block;
  }

  .live_report_toggle {
    justify-content: space-between;
  }
}

.announcement_input {
  width: 90%;
  margin-right: 6px;
  padding: 7px;

  @media only screen and (max-width: 600px) {
    width: 75%;

  }

}