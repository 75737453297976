.dropdown {
    display: inline-block;
    position: relative;
}

.dd-button {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #1ab394;
    cursor: pointer;
    white-space: nowrap;
}

.dd-button:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.dd-button:hover {
    background-color: #1ab394;
}


.dd-input {
    display: none;
}

.dd-menu {
    position: fixed;
    top: 144px;
    height: 120px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    list-style-type: none;
}

.dd-input+.dd-menu {
    display: none;
}

.dd-input:checked+.dd-menu {
    display: block;
}

.dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #1ab394;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

.live_animation {
    color: #F60000;
    margin-left: "2px";
    font-size: "10px";
    animation: live_anim 1s ease-in-out infinite;
}

@keyframes live_anim {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(0.7);
    }

    100% {
        transform: scale(0.9);
    }
}